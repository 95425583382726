import React, { useContext, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Context from "./context";
import SubmitButton from "./submit-button";
import SuccessModal from "./success-modal";

const ContactForm = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const formEl = useRef(null);

  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      object: "lost",
    },
  });

  const onSubmit = (data) => {
    setSuccess("");
    setError("");
    setIsButtonLoading(true);

    axios
      .post(`${process.env.GATSBY_SERVER_URL}.netlify/functions/api/contact`, {
        email: data.email,
        lastName: data.lastName,
        firstName: data.firstName,
        hotel: data.hotel,
        message: data.message,
        newsletter: data.newsletter,
        object: data.object,
        locale: data.locale,
        website: data.website,
        honeypot: data.honeypot,
      })
      .then(function (response) {
        setIsButtonLoading(false);

        if (response.status === 200) {
          formEl.current.reset();
          setSuccess(intl.formatMessage({ id: "form.success" }));
        } else {
          setError(intl.formatMessage({ id: "form.error" }));
        }
      })
      .catch(function (error) {
        console.error(error);
        setError(intl.formatMessage({ id: "form.error" }));
      });
  };

  const object = watch("object");

  return (
    <>
      <form ref={formEl} className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          style={{ display: "none" }}
          {...register("honeypot")}
        />
        <input
          type="hidden"
          value={intl.locale.toUpperCase()}
          {...register("locale")}
        />
        <input type="hidden" value="ace-hotel.com" {...register("website")} />
        <div className="form-field">
          <input
            type="text"
            id="firstName"
            placeholder={intl.formatMessage({ id: "form.first-name" })}
            {...register("firstName", { required: true })}
          />
          <label htmlFor="firstName">
            {intl.formatMessage({ id: "form.first-name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="text"
            id="lastName"
            placeholder={intl.formatMessage({ id: "form.name" })}
            {...register("lastName", { required: true })}
          />
          <label htmlFor="lastName">
            {intl.formatMessage({ id: "form.name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="email"
            id="email"
            placeholder={intl.formatMessage({ id: "form.email" })}
            {...register("email", { required: true })}
          />
          <label htmlFor="email">
            {intl.formatMessage({ id: "form.email" })}
          </label>
        </div>
        <div className="form-field">
          <select id="object" {...register("object", { required: true })}>
            <option value="lost">
              {intl.formatMessage({ id: "form.lost" })}
            </option>
            <option value="reclamation">
              {intl.formatMessage({ id: "form.claim" })}
            </option>
            <option value="other">
              {intl.formatMessage({ id: "form.other" })}
            </option>
          </select>
          <label htmlFor="object">
            {intl.formatMessage({ id: "form.subject" })}
          </label>
        </div>
        {(object === "reclamation" ||
          object === "lost" ||
          object === "other") && (
          <div className="form-field">
            <select id="hotel" {...register("hotel", { required: true })}>
              {data.allContentfulHotel.nodes.map((node, i) => {
                const email = node.email.split("@");
                return (
                  <option key={i} value={email[0]}>
                    {node.title}
                  </option>
                );
              })}
            </select>
            <label htmlFor="hotel">
              {intl.formatMessage({ id: "form.hotel" })}
            </label>
          </div>
        )}
        <div className="form-field">
          <textarea
            type="textarea"
            id="message"
            placeholder={intl.formatMessage({ id: "form.message" })}
            rows="5"
            cols="40"
            {...register("message")}
          />
          <label htmlFor="message">
            {intl.formatMessage({ id: "form.message" })}
          </label>
        </div>
        <div className="form-checkbox">
          <input type="checkbox" id="newsletter" {...register("newsletter")} />
          <label htmlFor="newsletter">
            {intl.formatMessage({ id: "form.newsletter" })}
          </label>
        </div>
        <div className="form-checkbox">
          <input
            type="checkbox"
            id="gdpr"
            {...register("gdpr", { required: true })}
          />
          <label htmlFor="gdpr">
            {intl.formatMessage({ id: "form.gdpr" })}
          </label>
        </div>
        <div className="d-flex justify-content-end mt-6">
          <SubmitButton isLoading={isButtonLoading}>
            {intl.formatMessage({ id: "form.send" })}
          </SubmitButton>
        </div>
      </form>
      <SuccessModal success={success} />
      {error && <p className="form-error">{error}</p>}
      {data.contentfulPage.seo_content && (
        <aside className="mt-12 mt-md-20">
          {renderRichText(data.contentfulPage.seo_content, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="small">{children}</p>
              ),
            },
          })}
        </aside>
      )}
    </>
  );
};

export default ContactForm;
